import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";
import CopyBtn from "src/components/Icon/CopyBtn";

import "./index.scss";
import { Rugbull } from "src/network/generated/pkg.pb";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { GetChainName, GetWithdrawState, Show } from "src/utils";
import { useTranslation } from "react-i18next";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useLogin } from "src/App";

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};


const LoginButton = () => {
  const { t } = useTranslation();
  const {googleLogin1} = useLogin();

  const login = useGoogleLogin({
    onSuccess: res => {
      googleLogin1(res);
    },
    scope: "openid profile",
  });

  return <div className="sign-btn-item" onClick={()=>{login()}}>{t('Sign.Desc')}</div>
}

const SignModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
 
  useEffect(() => {
  }, []);

  return (
    <Modal
      title={t('Sign.In')}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="sign-modal"
      style={{
        maxHeight: "80%",
      }}
    >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <div className="sign-modal-content">
            <div className="sign-btn">
              <div className="google"></div>
              {/* <div className="sign-btn-item" onClick={()=>{onOk();}}>{t('Sign.Desc')}</div> */}

              <LoginButton />
            </div>
          </div>
        </GoogleOAuthProvider>
    </Modal>
  );
};

export default SignModal;
